<template>
  <div class="accordion-item" :id="'myAccordion-' + props.item.id">
    <div class="accordion-header" :id="'heading-' + props.item.id">
      <button type="button" class="accordion-button rounded-0 collapsed" data-bs-toggle="collapse"
        :data-bs-target="'#collapse-' + props.item.id" aria-expanded="false"
        :aria-controls="'#collapse-' + props.item.id" @click="toggleAccordion">
        <span v-if="isCollapsed"
          class="material-icons-sharp material-icons-sharp-plus-minus-buttons material-icons-sharp-add-cirle" role="img"
          :aria-label=texts.filterAccordionItem.visaDetaljer></span>
        <span v-else
          class="material-icons-sharp material-icons-sharp-plus-minus-buttons material-icons-sharp-do-not-disturb-on"
          role="img" :aria-label=texts.filterAccordionItem.doljaDetaljer></span>
        <h3 class="item-header">{{ props.item.namn }}</h3>
      </button>
    </div>
    <div :id="'collapse-' + props.item.id" :class="['accordion-collapse', 'collapse', { show: !isCollapsed }]"
      :data-bs-parent="'#myAccordion-' + props.item.id">
      <div class="card-body">
        <div class="accordion-preamble" v-html="props.item.preamble"></div>
        <div class="accordion-separator"></div>
        <h4 v-if="props.item.adress && props.item.adress.length > 0" class="accordion-text">{{
          texts.filterAccordionItem.besoksadress }}</h4>
        <div v-if="props.item.adress && props.item.adress.length > 0" v-for="adress in props.item.adress">
          <span class="accordion-adress">{{ adress }}</span>
        </div>
        <div v-if="props.item.adress && props.item.adress.length > 0" class="accordion-separator"></div>
        <div>
          <h4 class="accordion-text">{{ texts.filterAccordionItem.kontaktaOss }}</h4>
          <div v-if="props.item.telefonnummer" class="accordion-kontakt-oss">
            <a class="hoverable-link-icon" :href="'tel:' + props.item.telefonnummer">
              <span class="material-icons-sharp material-icons-sharp-call" role="img"
                :aria-label=texts.filterAccordionItem.telefonnummer></span>
              <span class="link-text">{{ props.item.telefonnummer }}</span>
            </a>
          </div>
          <div v-if="props.item.epost" class="accordion-kontakt-oss">
            <a class="hoverable-link-icon" :href="'mailto:' + props.item.epost">
              <span class="material-icons-sharp material-icons-sharp-mail" role="img"
                :aria-label=texts.filterAccordionItem.mail></span>
              <span class="link-text">{{ props.item.epost }}</span>
            </a>
          </div>
        </div>
        <div class="accordion-separator"></div>
        <div v-if="props.item.web">
          <h4 class="accordion-text">{{ texts.filterAccordionItem.webbplats }}</h4>
          <div class="accordion-website">
            <a class="hoverable-link-icon" :href="props.item.web?.href" :target="props.item.web?.target"
              :title="props.item.web?.title">
              <span class="material-icons-sharp material-icons-sharp-open-in-new" role="img"
                :aria-label=texts.filterAccordionItem.websidanLank></span>
              <span class="link-text">{{ props.item.web?.text }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { Larosate } from "../../types";

export interface Props {
  item: Larosate;
  open: any;
}

const texts = computed(() => uhr.localizedTexts);
const props = defineProps<Props>();
const isCollapsed = ref(!props.open);

const toggleAccordion = () => {
  isCollapsed.value = !isCollapsed.value;
};
</script>
