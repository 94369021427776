<template>
    <ckeditor :editor="ClassicEditor" v-model="internalData" :config="editorConfig"></ckeditor>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const props = defineProps({
    editorData: String
});

const emits = defineEmits(['update:editorData']);

const internalData = ref(props.editorData);
const editorConfig = ref<any>({
    toolbar: [
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        'undo',
        'redo'
    ]
});

onMounted(() => {
    // Update internalData when editorData changes externally
    internalData.value = props.editorData;
    // internalData.value = '';
});

watch(internalData, (newValue) => {
    // Emit event when internalData changes
    emits('update:editorData', newValue);
});

watch(props, (newValue) => {
    if(internalData.value != newValue.editorData){
        internalData.value = newValue.editorData;
    }
});

</script>