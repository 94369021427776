<template>
  <div class="filtergroup">

    <h2>Filtrera</h2>
    <div class="form-group">
      <div v-if="!props.hideTextInput">
        <FilterTextInput parameter="q" id="T1" :label="textFilter.label || ''" :placeholder="textFilter.placeholdertext"
          :showBtn="true" :btnTxt="textFilter.btntext" />
      </div>
      <div v-if="!props.hideLarosate">
        <filterDropDown parameter="l" :filter-data="larosateFilterData" id="D1" />
      </div>
      <div v-if="!props.hideRoll">
        <filterDropDown parameter="r" :filter-data="rollFilterData" id="D4" />
      </div>
      <div v-if="!props.hideStatus">
        <filterDropDown parameter="s" :filter-data="dataStatus" id="D2" />
      </div>
      <div v-if="!props.hideKontoTyp">
        <filterDropDown parameter="kt" :filter-data="dataKontotyp" id="D3" />
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import filterDropDown from './FilterDropDown.vue'
import FilterTextInput from './FilterTextInput.vue';
import { filterStore } from './FilterStore';

import { FilterData } from '../../types';

import api from "../../services/SHServices";

const props = defineProps({
  hideTextInput: Boolean,
  hideLarosate: Boolean,
  hideStatus: Boolean,
  hideKontoTyp: Boolean,
  hideRoll: Boolean
});

const textFilter = {
  "label": "Sök på namn eller e-postadress",
  "placeholdertext": "Sök...",
  "btntext": "Sök"
};
// Hårdkodade data för dropdowns
import dataKontotyp from "./_dataKontotyp.json"
import dataStatus from "./_dataStatus.json"

// Håller data för lärosätenDropdown
const larosateFilterData = ref<FilterData>({ labelText: "", options: [] });
const rollFilterData = ref<FilterData>({ labelText: "", options: [] });

// hämtar data för dropdown för lärosäten
const SetupLarosatesDropdown = () => {
  api.getLarosatenFilterData().then((r) => {
    r.options.unshift({ label: "Alla", value: "" });  // lägger till "Alla" som första alternativ
    r.labelText = "Lärosäte";
    larosateFilterData.value = r;
  });
}

// hämtar data för dropdown för lärosäten
const SetupRollDropdown = () => {
  api.getUsersRoles().then((r) => {
    r.options.unshift({ label: "Alla", value: "" });  // lägger till "Alla" som första alternativ
    r.labelText = "Roll";
    rollFilterData.value = r;
  });
}

onBeforeMount(() => {
  SetupLarosatesDropdown();
  SetupRollDropdown();
});

// läser in url-parametrar och sätter dessa i filterStore
filterStore.makeQueryParamsFromUrl();

</script>