import {
	Topic,
	CreateTopicParams,
	GetTopicParams,
	PaginationResult,
	CreateMessageParams,
	DeleteMessageParams,
	DeleteTopicParams,
	EditMessageParams,
	GetMessagesParams,
	MessagesPaginated,
} from "../YafTypes";
const apiBaseURl = "/api/yaf/";

export const loadTopicsWithPagination = async (
	getTopicsParams: GetTopicParams
): Promise<PaginationResult<Topic>> => {
	// Construct the URL
	const url = apiBaseURl + "TopicsWithPagination";

	try {
		// Make the POST request
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(getTopicsParams),
		});

		// Check if the response was OK
		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		// Parse the JSON response
		const jsonResponse = (await response.json()) as PaginationResult<Topic>;
		return jsonResponse;
	} catch (error) {
		// Log or handle errors appropriately
		console.error("Failed to load topics:", error);
		throw error; // Rethrow or handle error as needed
	}
};

export const loadMessagesWithPagination = async (
	getMessagesParam: GetMessagesParams
): Promise<MessagesPaginated> => {
	// Construct the URL
	const url = apiBaseURl + "MessagesWithPagination";

	try {
		// Make the POST request
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(getMessagesParam),
		});

		// Check if the response was OK
		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		// Parse the JSON response
		const jsonResponse = (await response.json()) as MessagesPaginated;
		return jsonResponse;
	} catch (error) {
		// Log or handle errors appropriately
		console.error("Failed to load topics:", error);
		throw error; // Rethrow or handle error as needed
	}
};

// Define the exported constant using arrow function syntax
export const createTopic = (
	createTopicData: CreateTopicParams,
	upload: HTMLInputElement | null = null
): Promise<number> => {
	return fetch(apiBaseURl + "CreateTopic", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(createTopicData),
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error("Ett fel uppstod, kunde inte skapa ny tråd");
			}
			return response.json();
		})
		.then((responseData) => {
			if (upload?.files?.length) {
				return uploadFilesToMessage(upload.files, responseData.messageId).then(
					() => responseData.newId
				);
			} else {
				// Assuming the response has a field named "newId"
				return responseData.newId;
			}
		});
};

//
export const createMessage = (
	createMessageData: CreateMessageParams,
	upload: HTMLInputElement | null = null
): Promise<number> => {
	return fetch(apiBaseURl + "Reply", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(createMessageData),
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error("Ett fel uppstod, kunde inte skapa nytt meddelande");
			}
			return response.json();
		})
		.then((responseData) => {
			if (upload?.files?.length) {
				return uploadFilesToMessage(upload.files, responseData.newId);
			} else {
				// Assuming the response has a field named "newId"
				return responseData.newId;
			}
		});
};

export const editMessage = (
	editdMessageParams: EditMessageParams,
	upload: HTMLInputElement | null = null
) => {
	return fetch(apiBaseURl + "EditMessage", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(editdMessageParams),
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error("Ett fel uppstod, kunde inte uppdatera meddelande");
			}
		})
		.then(() => {
			if (upload?.files?.length) {
				return uploadFilesToMessage(upload.files, editdMessageParams.messageId);
			} else {
				// Assuming the response has a field named "newId"
				return editdMessageParams.messageId;
			}
		});
};

const uploadFilesToMessage = (files: FileList, messageId: number) => {
	const formData = new FormData();
	formData.append("messageId", messageId.toString());
	for (let i = 0; i < (files.length ?? 0); i++) {
		const f = files[i];
		formData.append("files", f, f.name);
	}
	return fetch("/api/yaf/attachfile", {
		method: "post",
		body: formData,
	}).then((response) => {
		if (!response.ok) {
			throw new Error(
				"Ett fel uppstod, kunde inte ladda upp filer för meddelande"
			);
		}
		return messageId;
	});
};

export const deleteMessage = (deleteMessageData: DeleteMessageParams) => {
	return fetch(apiBaseURl + "DeleteMessage", {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(deleteMessageData),
	}).then((response) => {
		if (!response.ok) {
			throw new Error("Ett fel uppstod, kunde inte radera meddelande");
		}
		return response.json();
	});
};

export const deleteTopic = (deleteTopicData: DeleteTopicParams) => {
	return fetch(apiBaseURl + "DeleteTopic", {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(deleteTopicData),
	}).then((response) => {
		if (!response.ok) {
			throw new Error("Ett fel uppstod, kunde inte radera tråd");
		}
		return response.json();
	});
};
