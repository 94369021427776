<template>
  <div class="nav-block">
    <hr>
    <div class="nav-header">
      <h2>{{ props.Heading }}</h2>
      <div class="buttons-container" v-if="!isMobile">
        <button @click="toggleViewMode(false)" :class="`${isListView ? '' : 'active'}`"><i
            class="material-icons-sharp icon-menu material-icons-sharp-menu"
            :aria-label=texts.navBlock.visaSomLista></i></button>
        <button @click="toggleViewMode(true)" :class="`${isListView ? 'active' : ''}`"><i
            class="material-icons-sharp material-icons-sharp-grid-view"
            :aria-label=texts.navBlock.visaSomRutnat></i></button>

      </div>
    </div>

    <Transition mode="out-in">
      <div v-if="!isListView">
        <Navlist :isListView="isListView" :ResultList="resultList"></Navlist>
      </div>
      <div v-else-if="isListView">
        <Navlist :isListView="isListView" :ResultList="resultList"></Navlist>
      </div>
    </Transition>
  </div>
  <div></div>
</template>


<script setup lang="ts">
import { ref, onMounted, watchEffect, computed } from 'vue';
import api from "../../services/SHServices";
import { NavBlockItem } from '../../types';
import Navlist from './Navlist.vue';

export interface Props {
  Heading: string
  ContentID: number
  showKort: boolean
}

const texts = computed(() => uhr.localizedTexts);
const props = defineProps<Props>()
let isListView = ref<boolean>(props.showKort);

const resultList = ref<Array<NavBlockItem>>([]);

const isMobile = ref<boolean>(false);

onMounted(async () => {
  api.getNavBlockItems().then(result => {
    resultList.value = result;
  });
});

watchEffect(() => {
  if (window.innerWidth < 776) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }
});

function toggleViewMode(value: boolean) {
  isListView.value = value;
}
</script>