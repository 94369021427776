<template>
    <div>
        <label :for="id">{{ label }}</label>
        <div class="input-group filter-text-input">
            <input v-model="elementValue" :id="id" type="text" class="form-control filter-text-input-input"
                @blur="updateFilterStore" :placeholder="props.placeholder" />
            <div v-if="props.showBtn" class="input-group-append">
                <button class="form-control filter-text-input-btn" type="button" v-on:click="updateFilterStore">{{
                    props.btnTxt }}</button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { onMounted } from 'vue';
import { filterStore } from './FilterStore';

const elementValue = defineModel("");

function updateFilterStore() {
    let text: string = elementValue.value as string;
    if (text === "" || text === undefined) {
        filterStore.removeParameter(props.parameter);
    } else {
        filterStore.setParameter(props.parameter, text);
    }
}
onMounted(() => {
    let urlParam: string | undefined = filterStore.getParameter(props.parameter);
    if (urlParam !== undefined) {
        elementValue.value = urlParam;
    }
})

const props = defineProps({
    id: { type: String, required: true, default: 'T1' },
    parameter: { type: String, required: true, default: 'q', },
    label: { type: String, required: true, },
    placeholder: { type: String, required: true, default: 'Sök...' },
    showBtn: {type: Boolean, required: false, default: false},
    btnTxt: { type: String, required: false, default: 'Sök' },
});


</script>