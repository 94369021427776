<template>
  <div class="accordionquestionblock-navigation">
    <div class="header-accordionblock">
      <h2 class="heading-block">
        {{ heading }}</h2>
      <div class="preamble-block">
        {{ preamble }}</div>
    </div>
    <div class="search-container">
      <div class="questionsearch">
        <div class="questionsearch-searchinputgroup">
          <SearchField :placeholder="searchText" v-model="query" />
        </div>
      </div>
    </div>
    <div class="bottom-accordionblock">
      <div class="questionList-container">
        <div class="question-list accordion" v-if="listData">
          <QuestionBlockItem v-for="(item, index) in listData" :item="item" :key="index">
          </QuestionBlockItem>
        </div>
      </div>
      <div class="readMore-Container" v-if="showReadMore">
        <button @click="$emit('loadMore')" class="readMore-button" id="">{{ texts.accordionBlock.lasMer
          }}</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { QuestionItem } from "../../types";
import QuestionBlockItem from './QuestionBlockItem.vue';
import SearchField from '../SearchField.vue';

const props = defineProps({
  items: {
    type: Array<QuestionItem>,
    default: []
  },
  heading: String,
  preamble: String,
  searchText: String,
  showReadMore: Boolean
});

const texts = computed(() => uhr.localizedTexts);
const query = ref<string>();


const listData = computed(() => {
  const searchValue = query.value?.toLowerCase().trim();
  if (!searchValue) {
    return props.items;
  }

  var list = props.items.filter(item => (item.heading?.toLowerCase() ?? '').includes(searchValue));
  return list
});
</script>
