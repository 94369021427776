<template>
  <span>Visar {{ users.length }} av {{ allUsers.length }} medarbetare</span>
  <div class="users" v-for="(person, index) in users" :key="index">
    <div class="medarbetar-info-ruta">
      <p>{{ texts.accounts.name }}: {{ person.fullName }}</p>
      <p>{{ texts.accounts.role }}: {{ person.roleInOrganisation }}</p>
      <p>{{ texts.accounts.email }}: {{ person.email }}</p>
      <p>{{ texts.accounts.org }}: {{ person.organisation }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { KontoUser } from "../../types";
import api from "../../services/SHServices";
import { onMounted, ref, watch, computed } from "vue";
import { filterStore } from './FilterStore';

const texts = computed(() => uhr.localizedTexts);
const users = ref<KontoUser[]>([]);
const allUsers = ref<KontoUser[]>([]);

onMounted(async () => {
  fetchUsers(); // start with no filter
  fetchAllUsers();
});

const fetchUsers = () => {
  api.getMemberUsers(filterStore.queryString)
    .then(r => {
      users.value = r;
    });
};

const fetchAllUsers = () => {
  api.getMemberUsers("")
    .then(r => {
      allUsers.value = r;
    });
};

watch(filterStore, () => {
  fetchUsers();
})

</script>
