<template>
    <div class="forum">
        
        <h1>{{ forumSidaProps.name }}</h1>
        <p v-if="$route.name === 'forumroot'"> {{ forumSidaProps.forumdescription }}</p>
        <RouterView/>
    </div>
</template>

<script setup lang="ts">
const forumSidaProps = defineProps({
    forumid: Number,
    name: String,
    pagehits: Number,
    forumdescription: String
});


</script>

<style>
    .topic-textinput, #topicForm {
        margin-bottom: 10px;
    }

    .btn {
        margin-right: 10px;
    }

    .topicForm-buttons {
        margin-bottom: 10px;
    }

    #topicForm {
        margin-top: 10px;
    }
</style>