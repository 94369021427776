import {
	ContentLoader,
	ContentData,
	ContentCollection,
} from "@episerver/content-delivery";
import {
	EventSearchResult,
	EventListItem,
	Larosate,
	NavBlockItem,
	NavBlockItemExtended,
	SearchResult,
	AccordionBlock,
	KontoUser /*KontoUser*/,
	FilterData,
} from "../types";

const baseHost = ""; //window.location.protocol + "//" + window.location.host; //not needed if same server
const apiUrl = `${baseHost}/api/episerver/v3.0/`;
const searchUrl = `${baseHost}/api/sh/search`;

const contentLoader = new ContentLoader({
	apiUrl: apiUrl,
});

//dokumentation epi content api frontend
//https://github.com/episerver/content-delivery-js-sdk/tree/master/src/%40episerver/content-delivery
function get<T>(id: number, properties: any): Promise<T> {
	const propList = Object.getOwnPropertyNames(properties);
	const select = ["contentLink", ...propList];

	var requestParams = {
		branch: uhr.page.lang,
		select: select,
	};

	return new Promise<T>((resolve, reject) => {
		const apiCall = contentLoader.getContent(id.toString(), requestParams);

		apiCall
			.then((r) => {
				const o = r as any;
				let ret = { id: o.contentLink.id } as T;
				propList.forEach((prop) => {
					(<any>ret)[prop] = o[prop]?.value ?? o[prop];
				});

				resolve(ret);
			})
			.catch((reason) => {
				console.error("Failed to get with id " + id, reason);
				reject(reason);
			});
	});
}

function list<T>(
	parent: number,
	properties: any,
	max: number = 1000
): Promise<Array<T>> {
	const propList = Object.getOwnPropertyNames(properties);
	const select = ["contentLink", ...propList];

	const requestParams = {
		top: max,
		branch: uhr.page.lang,
		select: select,
	};

	return new Promise<Array<T>>((resolve, reject) => {
		const apiCall = contentLoader.getChildren(
			parent.toString(),
			requestParams
		) as Promise<ContentCollection<ContentData>>;

		apiCall
			.then((r) => {
				const items = r.items?.map<T>((i) => {
					const o = i as any;
					let ret = { id: o.contentLink.id } as T;
					propList.forEach((prop) => {
						(<any>ret)[prop] = o[prop]?.value ?? o[prop];
					});
					return ret as T;
				}) as Array<T>;

				resolve(items);
			})
			.catch((reason) => {
				console.error("Failed to list from parent " + parent, reason);
				reject(reason);
			});
	});
}

//TODO: Flytta? Ändra till generisk metod?
//TODO: Kan använda list ovan.. Returtyp ser knepig ut. returnera promisen om det är det som är specat..
//vet inte om den används..
const listEvents = async (): Promise<Array<EventListItem>> => {
	const response = await fetch(`${baseHost}/api/event/list/${uhr.page.id}`);
	const data = await response.json();
	return data;
};

const relatedEvents = async (
	root: number,
	filter: Array<number> | null = null
): Promise<Array<EventListItem>> => {
	var url = `${baseHost}/api/event/related/${root}/${uhr.page.id}`;
	if (filter) {
		var filterstr: string = "";
		filterstr = "?filterCat=" + filter.join("&filterCat=");

		url += filterstr;
	}
	const response = await fetch(url);
	const data = await response.json();
	return data.slice(0, 3);
};

interface SearchEventParams {
	query: string;
	digital: boolean;
	local: boolean;
	startDate: Date;
	range: Array<Date | null>;
	day: boolean;
	night: boolean;
	category: Array<string>;
	take: number;
	location: Array<string>;
	root: number | null;
}

const searchEvents = async (
	p: SearchEventParams
): Promise<EventSearchResult> => {
	return new Promise<EventSearchResult>((resolve, reject) => {
		fetch(`${baseHost}/api/event/search`, {
			method: "post",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(p),
		})
			.then((r) => r.json())
			.then((r) => {
				resolve(r as EventSearchResult);
			})
			.catch((reason) => {
				console.error("search event failed", reason);
				reject(reason);
			});
	});
};

//TODO: Flytta? Ändra till generisk metod?
const getAccordionData = async (
	id: number,
	showAll: boolean = false
): Promise<AccordionBlock> => {
	const response = await fetch(
		`${baseHost}/api/AccordionBlock/list?id=${id}&allQuestions=${
			showAll ? 1 : 0
		}`
	);
	const data = await response.json();
	return data;
};

const getLarosaten = () =>
	list<Larosate>(
		uhr.settings.universityRoot,
		{
			adress: [],
			epost: "",
			heading: "",
			namn: "",
			preamble: "",
			telefonnummer: "",
			web: "",
		},
		100
	);

const getNavBlockItems = (): Promise<NavBlockItem[]> =>
	list<NavBlockItemExtended>(
		uhr.page.id,
		{
			name: "",
			heading: "",
			preamble: "",
			description: "",
			url: "",
		},
		100
	).then((result) =>
		result.map((i) => {
			if (!i.heading?.length && i.name?.length) {
				i.heading = i.name;
			}

			if (!i.preamble?.length && i.description?.length) {
				i.preamble = i.description;
			}
			return i;
		})
	);

const search = (query: string, filter: string): Promise<SearchResult> => {
	let url = searchUrl + "?query=" + encodeURIComponent(query);
	if (filter && filter.length) {
		url += "&filter=" + encodeURIComponent(filter);
	}

	if (uhr.settings.searchRoot) {
		url += "&root=" + uhr.settings.searchRoot;
	}

	url += "&lang=" + uhr.page.lang;

	return new Promise<SearchResult>((resolve, reject) => {
		fetch(url)
			.then((r) => r.json())
			.then((r) => {
				resolve(r as SearchResult);
			})
			.catch((reason) => {
				console.error("search failed", reason);
				reject(reason);
			});
	});
};

const getAccountUsers = async (queryString: string): Promise<KontoUser[]> => {
	var url = `${baseHost}/api/kontohantering/users` + queryString;
	const response = await fetch(url);
	const data = await response.json();
	return data;
};

const getMemberUsers = async (queryString: string): Promise<KontoUser[]> => {
	var url = `${baseHost}/api/kontohantering/members` + queryString;
	const response = await fetch(url);
	const data = await response.json();
	return data;
};

const getLarosatenFilterData = async (): Promise<FilterData> => {
	var url = `${baseHost}/api/kontohantering/filterData/larosate`;
	const response = await fetch(url);
	const data = await response.json();
	return data;
};

const getUsersRoles = async (): Promise<FilterData> => {
	var url = `${baseHost}/api/kontohantering/filterData/roll`;
	const response = await fetch(url);
	const data = await response.json();
	return data;
};

const approveUser = async (userName: string): Promise<KontoUser> => {
	var url = `${baseHost}/api/kontohantering/approve/${userName}/`;
	const response = await fetch(url);

	const data = await response.json();
	if (data.errorCode) {
		console.log("Fel vid beviljning av användare");
	}
	return data;
};

const rejectUser = async (userName: string): Promise<KontoUser> => {
	var url = `${baseHost}/api/kontohantering/reject/${userName}/`;
	const response = await fetch(url);
	const data = await response.json();

	if (data.errorCode) {
		console.log("Fel vid nekning av användare");
	}
	return data;
};

const updateUserName = async (
	userName: string,
	newName: string
): Promise<KontoUser> => {
	var url = `${baseHost}/api/kontohantering/updatename/${userName}/${newName}/`;
	const response = await fetch(url);
	const data = await response.json();
	if (data.errorCode) {
		console.log("Fel vid uppdatering av namn");
	}
	return data;
};

const updateUserRole = async (
	userName: string,
	newRole: string
): Promise<KontoUser> => {
	var url = `${baseHost}/api/kontohantering/updaterole/${userName}/${newRole}/`;
	const response = await fetch(url);
	const data = await response.json();

	if (data.errorCode) {
		console.log("Fel vid uppdatering av roll");
	}
	return data;
};

export type { SearchEventParams };

export default {
	listEvents,
	relatedEvents,
	searchEvents,
	approveUser,
	rejectUser,
	updateUserName,
	updateUserRole,
	getNavBlockItems,
	getLarosaten,
	getAccordionData,
	getAccountUsers,
	getMemberUsers,
	getLarosatenFilterData,
	getUsersRoles,
	list,
	get,
	search,
};
