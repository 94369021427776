import { reactive } from 'vue'

export const filterStore = reactive({
  queryString: "",
  queryParams: new Map<string, string>(),

  setParameter(key: string, value: string) {
    this.queryParams.set(key, value)
    this.buildQueryString();
  },
  getParameter(key: string):string | undefined {
    return this.queryParams.get(key);
  } 
  ,
  removeParameter(key: string) {
    this.queryParams.delete(key)
    this.buildQueryString();
  },

  makeQueryParamsFromUrl() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    params.forEach((value: string, key: string) => {
      this.queryParams.set(key, value)
    })

  },

  buildQueryString() {
    let newQueryString:string = "";
    this.queryParams.forEach((value: string, key: string) => {
      newQueryString += `${key}=${value}&`
    })
    newQueryString = newQueryString.slice(0, -1);
    this.queryString = "?" + newQueryString;
  }

})
