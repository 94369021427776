<template>
    <ul v-if="ResultList" class="d-flex row flex-row flex-wrap">
        <NavListItem :isListView="props.isListView" v-for="(item, index) in props.ResultList" :itemInfo="item"
            :key="index">
        </NavListItem>
    </ul>
    <div v-else>{{ texts.navlist.detFinnsIngaSokresultat }}</div>
</template>

<script setup lang="ts">
import NavListItem from './NavListItem.vue';
import { computed } from 'vue';
export interface Props {
    ResultList: any[],
    isListView: boolean
}
const texts = computed(() => uhr.localizedTexts);
const props = defineProps<Props>();

</script>