<template>
    <div v-if="resultList.length > 0">
        <div class="event-releatedlist-block">
            <div class="releatedlist-header container">
                <h2>{{ props.Heading }}</h2>
                <p class="d-lg-none">{{ texts.eventRelatedlistBlock.relatedEvents }}</p>
            </div>
            <div class="releatedlist-result-wrapper">

                <!-- MOBIL -->
                <div class="releatedlist-result d-lg-none container">
                    <div v-if="resultList.length === 1" class="event-list">
                        <EventListItemComponent :itemInfo="resultList[0]" :carouselActive="1" />
                    </div>
                    <div v-if="resultList.length > 1" id="liknandeEventCarousel" class="event-list carousel slide"
                        data-bs-touch="true" data-bs-interval="false">
                        <EventListItemCarouselWrapper :ResultList="resultList" />
                    </div>
                </div>

                <!--DESKTOP-->
                <div class="releatedlist-result d-none d-lg-block container ">
                    <div v-if="resultList.length > 0" class="event-list row row-cols-1 row-cols-md-3 g-3">
                        <EventListItemComponent v-for="(item, index) in resultList" :itemInfo="item" :key="index" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import api from "../../services/SHServices";
import { EventListItem } from '../../types';
import EventListItemComponent from './EventListItem.vue';
import EventListItemCarouselWrapper from './EventListItemCarouselWrapper.vue';

export interface Props {
    Heading: string
    RootID: number,
    FilterListId: Array<number>,
    Carousel: boolean
}

const texts = computed(() => uhr.localizedTexts);
const props = defineProps<Props>()
const resultList = ref<EventListItem[]>([]);

onMounted(async () => {
    //finns även i uhr.page.id
    api.relatedEvents(props.RootID, props.FilterListId).then(result => {
        resultList.value = result;
    });

});

</script>