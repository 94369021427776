<template>
    <div class="pagination pagination-centered" v-if="pagination && pagination?.numberOfPages > 1">
        <nav class="paginator" aria-label="Paginering">
            <ul>
            <li v-if="pagination?.hasPreviousPage">
                <button @click="buttonClick(pagination?.previousPage)" data-paging='' class="pagenumber previous" rel='prev'
                    :aria-label="`Föregående : Sida ${pagination.previousPage}`"
                    :title="`Föregående : Sida ${pagination.previousPage}`">
                    Föregående
                </button>
            </li>
            <li v-if="pagination && pagination.currentPage >= 6">
                <button class="pagenumber" @click="buttonClick(1)">
                    1..
                </button>
            </li>
            <li v-for="(result) in pagination?.pagingItems">
                <button @click="buttonClick(result.index)"  data-paging='' :class="getClass(result)" :rel="getRel(result)" :aria-label="getAriaLabel(result)"
                    :title="getAriaLabel(result)" href='#'>{{ result.index }}</button>
            </li>
            <li v-if="pagination?.hasNextPage">
                <button @click="buttonClick(pagination?.nextPage)" data-paging='' class='pagenumber' rel='next'
                    :aria-label="`Nästa : Sida ${pagination.nextPage}`"
                    :title="`Nästa : Sida ${pagination.nextPage}`" href='#'>
                    Nästa
                </button>
            </li>
        </ul>
        </nav>
    </div>
</template>
<script setup lang="ts">
import { Pagination, PagingItems } from '../YafTypes';

interface Props {
    pagination?: Pagination;
}
const props = defineProps <Props>();
const emits = defineEmits(['handlePageChange']);


function getClass(result : PagingItems) {
    if (props.pagination?.currentPage === result.index) {
        return "pagenumber active"
    } else {
        return "pagenumber"
    }
}

function buttonClick(pagenumber? : Number){
    emits('handlePageChange', pagenumber);
}

function getRel(result : PagingItems) {
    if (props.pagination?.currentPage === result.index) {
        return "active"
    } else if (props.pagination?.hasPreviousPage && result.index && result.index < props.pagination?.currentPage) {
        return "prev"
    } else {
        return "next"
    }
}

function getAriaLabel(result : PagingItems) {
    if (props.pagination?.currentPage === result.index) {
        return `Visas nu:  Sida ${result.index}`
    } else if (props.pagination?.hasPreviousPage && result.index && result.index < props.pagination?.currentPage) {
        return `Föregående:  Sida ${result.index}`
    } else {
        return `Nästa:  Sida ${result.index}`
    }
}
</script>
