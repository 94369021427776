<template>
    <div class="col d-flex">
        <div class="event-list-item clickableArea" v-if="props.itemInfo">
            <div
                :class="{ 'event-item': true, 'd-flex': true, 'flex-column': true, 'marked': true, 'internal': isInternalLink(props.itemInfo.url) }">
                <div class="d-flex justify-content-between">
                    <div>
                        <a :href="props.itemInfo.url">
                            <h3 class="event-item-header">{{ props.itemInfo.heading }}</h3>
                        </a>
                        <span v-if="props.itemInfo.isEventRemote" class="formattype">{{ texts.eventListItem.digitalt
                            }}</span>
                        <span v-if="props.itemInfo.location !== null" class="formattype">{{ texts.eventListItem.paPlats
                            }}</span>
                    </div>
                    <div class="d-flex flex-column icon-container">
                        <span v-if="props.itemInfo.isEventRemote"
                            class="material-icons-sharp material-icons-sharp-videocam" aria-hidden="true"></span>
                        <span v-if="props.itemInfo.location !== null"
                            class="material-icons-sharp material-icons-sharp-home-work" aria-hidden="true"></span>
                    </div>
                </div>

                <span class="event-item-date">{{ convertEventDate(props.itemInfo.startDate, props.itemInfo.endDate)
                    }}</span>
                <p class="event-item-preamble"> {{ props.itemInfo.preamble }}</p>

                <div class="event-item-bottom-wrapper">
                    <hr />
                    <div class="event-item-tags-wrapper">
                        <span v-for="(item, index) in props.itemInfo.categories" class="event-item-tags"
                            :key="index">#{{
                                item
                            }}</span>
                    </div>
                    <span class="event-item-organizer m-0">{{ props.itemInfo.organizer }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { EventListItem } from "../../types";
import { isInternalLink, convertEventDate, setupClickableAreas } from "../../utils"
import { onMounted } from 'vue';
import { computed } from 'vue'

const texts = computed(() => uhr.localizedTexts);

type Props = {
    itemInfo: EventListItem
}

const props = defineProps<Props>();

onMounted(async () => {
    // Handle clicks on .clickableArea
    setupClickableAreas("clickableArea");
});

</script>
