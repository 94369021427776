<template>
    <div class="topicForm-buttons" v-if="hideButtonDiv">
        <button v-show="showCreateButton" id="createMessage" class="btn btn-outline-primary btn-small" type="button"
            @click="toggleButtons" data-bs-toggle="collapse" :data-bs-target="editorName(true)" aria-expanded="false"
            :aria-controls="editorName()">
            {{ props.buttonText }}
        </button>
        <div class="collapse messageEditor" :id="editorName()">
            <CKEditor :editorData="editorData" @update:editorData="updateEditorData" class="topic-editor" />
            <br />
            <label for="upload-file">Filer:</label><br />
            <input id="upload-file" type="file" ref="upload" multiple>
        </div>
        <div>
            <button v-show="showSaveCancelButton" id="saveMessage" class="btn btn-primary btn-small" type="button"
                @click="sendData" data-bs-toggle="collapse" :data-bs-target="editorName(true)" aria-expanded="false"
                :aria-controls="editorName()">
                Svara
            </button>
            <button v-show="showSaveCancelButton" id="cancelNewMessage" class="btn btn-outline-primary btn-small"
                @click="cancel" type="button" data-bs-toggle="collapse" :data-bs-target="editorName(true)"
                aria-expanded="false" :aria-controls="editorName()">
                Avbryt
            </button>
        </div>
    </div>
    <p id="statusMsg">{{ status_message }}</p>
</template>

<script setup lang="ts">
import CKEditor from './Editor.vue';
import { createMessage } from '../../services/YafService';
import { CreateMessageParams } from "../../YafTypes";
import { ref } from 'vue';

const showCreateButton = ref(true);
const showSaveCancelButton = ref(false);
const status_message = ref('');
const inputTopic = ref('');
const editorData = ref('');
const hideButtonDiv = ref(true);
const upload = ref<HTMLInputElement | null>(null);

const emits = defineEmits(['loadMessages']);


const props = defineProps({
    replyToId: Number,
    buttonText: String
});

const editorName = (prependHash = false) => (prependHash ? '#' : '') + 'editor_' + props.replyToId;


function toggleButtons() {
    showCreateButton.value = !showCreateButton.value;
    showSaveCancelButton.value = !showSaveCancelButton.value;
}

function cancel() {
    inputTopic.value = ''; // Clear the input
    updateEditorData('');

    toggleButtons();
    if (upload.value) {
        upload.value.value = '';
    }
}

function updateEditorData(newData: string) {
    editorData.value = newData;
}

function sendData() {
    const htmlValue = editorData.value;
    const createMessageParams: CreateMessageParams = {
        replyTo: props.replyToId!,
        message: htmlValue,
    };

    createMessage(createMessageParams, upload.value)
        .then(() => {
            emits('loadMessages'); // Load new messages after sending
            inputTopic.value = ''; // Clear the input after sending
            updateEditorData('');
            if (upload.value) {
                upload.value.value = '';
            }
        })
        .catch(error => {
            console.error("Ett fel uppstod, kunde inte skapa nytt meddelande", error);
            status_message.value = "Failed to create message";
        });

    toggleButtons();
}

</script>

<style>
.messageEditor {
    margin-top: 10px;

}

.topic-textinput,
#topicForm {
    margin-bottom: 10px;
}

.btn {
    margin-top: 10px;
    margin-right: 10px;
}

.topicForm-buttons {
    margin-bottom: 10px;
}

#messageForm {
    margin-top: 10px;
}
</style>