<template>
  <div class="swedishinstitutionmappage-subNavigation">
    <div class="institutionsearch mb-5">
      <SearchField v-model="query" :placeholder=texts.filterblock.sokUniversitetHogskola @keyup="searchListOnKeyUp"
        @search-click="doSearch" />
    </div>
    <div>
      <div class="result-container mb-5 d-flex flex-nowrap " aria-live="polite">
        <h2 class="result-count-label fw-bold">{{ texts.filterblock.visarResultat }}</h2>
        <h2 class="result-count">{{ filteredItems?.length ?? '-' }} {{ texts.filterblock.larosate }}</h2>
      </div>
      <div class="institution-list accordion" v-if="filteredItems">
        <FilterAccordionItem v-for="(item, index) in filteredItems" :item="item" :open="open == item.id" :key="index">
        </FilterAccordionItem>
      </div>
      <div v-else>{{ texts.filterblock.detFinnsIngaSokresultat }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import api from "../../services/SHServices";
import { Larosate } from "../../types";
import FilterAccordionItem from './FilterAccordionItem.vue';
import SearchField from '../SearchField.vue';
import { debounce, getHashAsDictionary } from '../../utils';

const texts = computed(() => uhr.localizedTexts);
const allItems = ref<Array<Larosate>>([]);
const filteredItems = ref<Array<Larosate>>([]);
const query = ref<string>();
const open = ref<number>(0);

onMounted(async () => {
  var dict = getHashAsDictionary();
  open.value = <number>(<any>dict.sel);
  api.getLarosaten().then(result => {
    allItems.value = result;
    filteredItems.value = result;
  });
});


const doSearch = () => {
  const searchValue = query.value?.toLowerCase().trim();
  if (!searchValue) {
    filteredItems.value = allItems.value;
    return;
  }

  filteredItems.value = allItems.value.filter(item => {
    const itemName = item.namn?.toLowerCase() ?? '';
    return itemName.includes(searchValue);
  });
}

const searchListOnKeyUp = debounce(doSearch, 100);

</script>