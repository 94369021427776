<template>
  <h2>Konton</h2>
  <div class="accordion" v-for="(person, index) in   users  " :key="index">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button accordion-button-users collapsed" type="button" data-bs-toggle="collapse"
          :data-bs-target="`#accordion-info-${index}`" aria-expanded="false" :aria-controls="`accordion-info-${index}`">
          <span :class="`status ${person.state}`">{{ person.state }}</span>
          {{ person.fullName }} - {{ person.email }}

        </button>
      </h2>
      <div :id="`accordion-info-${index}`" class="accordion-collapse accordion-collapse-users collapse">
        <div class="accordion-body">
          <p>{{ texts.accounts.name }}: {{ person.fullName }}</p>
          <p>{{ texts.accounts.email }}: {{ person.email }}</p>
          <p>{{ texts.accounts.org }}: {{ person.organisation }}</p>
          <p>{{ texts.accounts.role }}: {{ person.roleInOrganisation }}</p>
          <p>{{ texts.accounts.motivation }}: {{ person.motivering }}</p>
          <p>{{ texts.accounts.handledby }}: {{ person.handledBy }}</p>
          <p>{{ texts.accounts.requestSent }}: {{ person.requestSent }}</p>
          <p>{{ texts.accounts.requestUpdated }}: {{ person.requestUpdated }}</p>

          <div class="userEditBtns">
            <div>

              <button @click="approveUser(person)" class="btn btn-outline-primary"
                :class="{ hidden: checkState(person.state) }">{{ texts.accounts.approveuser }}</button>
              <button class="btn btn-outline-secondary" @click="showModalForPerson(person)">{{
    texts.accounts.denyuser
  }}</button>
            </div>
            <button @click="openEdit(person)" class="btn btn-outline-secondary">
              Redigera
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal reject-->
  <div v-if="showModal" class="modal-reject" @keydown="handleTabEvent($event, 'modal-content')">
    <div class="modal-content">
      <p>{{ texts.accounts.modalrejectmessage }}</p>
      <button @click="confirmRejectUser" class="btn btn-outline-primary confirm">{{
    texts.accounts.modalrejectconfirm }}</button>
      <button @click="cancelRejectUser" class="btn btn-outline-secondary">{{ texts.accounts.modalrejectcancel
        }}</button>
    </div>
  </div>
  <!-- Modal edit-->
  <div v-if="showEditModal" class="modal-reject" @keydown="handleTabEvent($event, 'modal-content')">
    <div class="modal-content">
      <h1>Redigera användare</h1>
      <div class="updateField">
        <div class="updateFieldInput">
          <p>{{ texts.accounts.name }}:
          </p>
          <input id="editname" class="edit-name" v-model="inputName">
        </div>
        <button @click="saveNewName(inputName)" class="btn btn-outline-primary btn-confirm">Uppdatera</button>
      </div>
      <div class="updateField">
        <div class="updateFieldInput">
          <p>{{ texts.accounts.role }}:
          </p>
          <input id="editrole" type="text" list="activeRolesList" class="edit-role" v-model="inputRole">
          <datalist id="activeRolesList">
            <option v-for="(r, _) in activeRoles">{{ r }}</option>
          </datalist>
        </div>
        <button @click="saveNewRole(inputRole)" class="btn btn-outline-primary btn-confirm">Uppdatera</button>
      </div>
      <button @click="cancelEditUser" class="btn btn-outline-secondary btn-close-modal">Stäng</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { KontoUser } from "../../types";
import api from "../../services/SHServices";
import { onMounted, ref, watch, computed, nextTick } from "vue";
import { handleTabEvent } from "../../utils";
import { filterStore } from './FilterStore';

const activePerson = ref<KontoUser>();
const texts = computed(() => uhr.localizedTexts);
const users = ref<KontoUser[]>([]);
const activeRoles = ref<string[]>([]);
const showModal = ref(false);
const showEditModal = ref(false);
const inputName = ref<string>("");
const inputRole = ref<string>("");

const showModalForPerson = (person: KontoUser) => {
  if (person) {
    activePerson.value = person;
    showModal.value = true;
  }
};

const openEdit = (person: KontoUser) => {
  if (person) {
    activePerson.value = person;
    inputName.value = person.fullName;
    inputRole.value = person.roleInOrganisation;
    showEditModal.value = true;
  }
};

onMounted(async () => {
  if (filterStore.queryString === "") {
    filterStore.setParameter("s", "Ny");
  }
  fetchAllUsers(); // start with no filter
  getAllRoles();
});

const fetchAllUsers = () => {
  api.getAccountUsers(filterStore.queryString)
    .then(r => {
      users.value = r;
    });
};

const getAllRoles = () => {
  api.getAccountUsers("").then(u => {
    const temp = u.map(user => user.roleInOrganisation);
    activeRoles.value = [... new Set(temp)];
  });
};

let focusableElements: HTMLElement[] = [];

watch(showModal || showEditModal, async (newValue) => {
  if (newValue === true) {
    await nextTick();
    await new Promise(resolve => setTimeout(resolve, 0));

    let modal = document.querySelector('.modal-content');

    if (modal) {
      focusableElements = Array.from<HTMLElement>(
        modal.querySelectorAll(
          'button:not([disabled]), [href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
        )
      );
      focusableElements[0].focus();
    }
  }
})
watch(filterStore, () => {
  fetchAllUsers();
})

const approveUser = (updatePerson: KontoUser) => {
  api.approveUser(updatePerson.userName).then(() => {
    fetchAllUsers();
    collapseToggle();

  });
}

const confirmRejectUser = () => {
  if (activePerson.value) {
    api.rejectUser(activePerson.value.userName).then(() => {
      fetchAllUsers();
      showModal.value = false; // Close the modal
      collapseToggle();

    });
    showModal.value = false; // Close the modal
  }
}

const saveNewName = (newName: string) => {
  if (activePerson.value) {
    api.updateUserName(activePerson.value.userName, newName).then(() => {
      fetchAllUsers();
    });
  }
};

const saveNewRole = (newRole: string) => {
  if (activePerson.value) {

    api.updateUserRole(activePerson.value.userName, newRole).then(() => {
      fetchAllUsers();
    });
  }
};

const cancelRejectUser = () => {
  showModal.value = false; // Close the modal
};

const cancelEditUser = () => {
  showEditModal.value = false;
};

const checkState = (userState: String) => {
  return (userState == "Beviljad");
}


const collapseToggle = () => {
  var usersBtns = document.getElementsByClassName("accordion-button-users");
  for (var userBtn of usersBtns) {
    userBtn.setAttribute("aria-expanded", "false");
    userBtn.classList.add("collapsed");
  }
  var usersPges = document.getElementsByClassName("accordion-collapse-users");
  for (var userPge of usersPges) {
    userPge.setAttribute("aria-expanded", "false");
    userPge.classList.remove("show");
  }
}

</script>