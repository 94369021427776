<template>
    <div ref="elementRef" class="video-container loading">
        <div class="overlay">
            <button v-if="!props.useModal" @click="toggle" class="btn play">
                <span class="material-icons-sharp"></span>
                <span class="sr-only sr-only-focusable">{{ texts.video.spelaFilm }} {{ props.heading }}</span>
            </button>
            <button v-if="props.useModal" data-bs-toggle="modal" :data-bs-target="modalId(true)" class="btn play">
                <span class="material-icons-sharp"></span>
                <span class="sr-only sr-only-focusable">{{ texts.video.spelaFilm }} {{ props.heading }}</span>
            </button>
        </div>
    </div>
    <h3 v-if="props.heading" v-html="props.heading"></h3>
    <div v-if="props.text" v-html="props.text" class="text"></div>

    <div class="modal video-modal fade" :id="modalId(false)" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <div ref="modalElementRef" class="video-container loading">
                        <button v-if="!props.useModal" @click="toggle" class="btn play">
                            <span class="material-icons-sharp"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, onBeforeUnmount, ref, computed } from 'vue';
import Player from '@vimeo/player';
const eventsToEmit = [
    'play',
    'playing',
    'pause',
    'ended',
    'timeupdate',
    'progress',
    'seeking',
    'seeked',
    'texttrackchange',
    'chapterchange',
    'cuechange',
    'cuepoint',
    'volumechange',
    'playbackratechange',
    'bufferstart',
    'bufferend',
    'error',
    'loaded',
    'durationchange',
    'fullscreenchange',
    'qualitychange',
    'camerachange',
    'resize'
];

export default {
    props: {
        vimeoId: Number,
        aspectRatio: {
            type: Number,
            default: 16 / 9
        },
        autoAspect: {
            type: Boolean,
            default: true
        },
        loop: {
            type: Boolean,
            default: false
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: true
        },
        uid: String,
        useModal: Boolean,
        heading: String,
        text: String
    }, setup(props, { emit }) {
        var player: Player;
        var containerElement: HTMLElement;
        const texts = computed(() => uhr.localizedTexts);
        let klaroConsentGiven = ref<boolean>(false);
        const elementRef = ref(null);
        const modalElementRef = ref(null);
        const play = () => player.play();
        const pause = () => player.pause();
        const toggle = () => {
            player.getPaused().then((paused: boolean) => {
                if (paused) {
                    play();
                } else {
                    pause();
                }
            }).catch((error: any) => {
                console.error('Error while checking paused', error);
            });
        };
        const mute = () => player.setVolume(0);
        const unmute = (volume = 0.5) => player.setVolume(volume);

        const modalId = (includeHash: boolean) => {
            return (includeHash ? '#' : '') + 'videoModal' + props.uid;
        };
        const bindEmits = (player: Player, event: string, emit: any) => {
            player.on(event, (data: any) => emit(event, data, player));
        };

        const setEvents = () => {
            player.ready().then(() => {
                emit('ready', player);
            }).catch((error: any) => {
                emit('error', error, player);
            })

            eventsToEmit.forEach(event => bindEmits(player, event, emit))
        }

        const setAspectRatio = (ratio: number, el: (HTMLElement | null) = null) => {
            const roundedAspect = Math.round(10000 / ratio) / 100;
            (el ?? containerElement).style.paddingBottom = roundedAspect + "%";
        }

        const addClass = (className: string, el: (HTMLElement | null) = null) => {
            (el ?? containerElement).classList.add(className);
        }
        const removeClass = (className: string, el: (HTMLElement | null) = null) => {
            (el ?? containerElement).classList.remove(className);
        }



        const startKlaro = () => {
            if (!elementRef.value) return;
            containerElement = <HTMLElement>elementRef.value;
            const tempElement = containerElement;
            player = new Player(elementRef.value, {
                id: props.vimeoId,
                loop: props.useModal ? false : (props.loop ?? false),
                autoplay: props.useModal ? false : (props.autoplay ?? false),
                controls: props.useModal ? false : (props.controls ?? true)
            });
            player.on('loaded', () => {
                removeClass("loading", tempElement);
                addClass("loaded", tempElement);
                if (props.autoAspect ?? true) {
                    Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(function (dimensions) {
                        setAspectRatio(dimensions[0] / dimensions[1], tempElement);
                    });
                }
            });
            if (props.useModal) {
                if (!modalElementRef.value) return;
                //if modal. redefine the player as the player in the modal window instead. 
                //let the original still instanciate for preview
                containerElement = <HTMLElement>modalElementRef.value;
                const tempElement = containerElement;
                player = new Player(containerElement, {
                    id: props.vimeoId,
                    loop: props.loop ?? false,
                    autoplay: props.autoplay ?? false,
                    controls: props.controls ?? true
                });
                player.on('loaded', () => {
                    removeClass("loading");
                    addClass("loaded");
                    if (props.autoAspect ?? true) {
                        Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(function (dimensions) {
                            setAspectRatio(dimensions[0] / dimensions[1], tempElement);
                        });
                    }
                });
                var modalElement = document.getElementById(modalId(false));
                modalElement?.addEventListener('shown.bs.modal', () => {
                    play();
                });
                modalElement?.addEventListener('hide.bs.modal', () => {
                    pause();
                });
            }

            player.on('playing', () => {
                removeClass("pause");
                addClass("playing");
            });
            player.on('ended', () => {
                removeClass("playing");
                removeClass("pause");
            });
            player.on('pause', () => {
                addClass("pause");
                removeClass("playing");
            });

            //set aspectratio
            if (props.aspectRatio) {
                setAspectRatio(props.aspectRatio ?? 16 / 9);
            }

            //fallback..
            if (!props.autoAspect && !props.aspectRatio) {
                console.warn('No aspect set for video ' + (props.vimeoId ?? 'NULL') + '. setting aspect to 1');
                setAspectRatio(1);
            }

            setEvents();
        };

        const checkConsentAndUpdatePlayer = () => {
            const consentGiven: boolean = klaro.getManager().consents.video;
            if (consentGiven) {
                klaroConsentGiven.value = true;
                startKlaro();
            } else {
                klaro.getManager(klaroConfig).watch({
                    update(_klaroConfig: any, eventName: string, consents: { [key: string]: boolean }) {
                        if (eventName === 'consents' && consents.video) {
                            klaroConsentGiven.value = true;
                            startKlaro();
                        }
                    }
                });
            }
        };

        onMounted(() => {
            checkConsentAndUpdatePlayer();
        });

        onBeforeUnmount(() => {
            player.unload();
        });

        return {
            texts, play, pause, toggle, mute, unmute, elementRef, modalElementRef, modalId, props
        }
    }
}
</script>
