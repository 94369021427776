<template>
    <li v-if="props.itemInfo" :class="`list ${props.isListView ? 'col-lg-3' : ''} col-sm-6 col-xs-12`">
        <a :href="props.itemInfo.url" :class="`list-item ${props.isListView ? 'card-view' : ''}`">
            <div class="item-container">
                <i class="material-icons-sharp arrow material-icons-sharp-arrow-forward-ios" role="img"></i>
                <span class="item-header" v-html="props.itemInfo.heading"></span>
            </div>
            <div v-if="props.isListView" class="item-preamble" v-html="props.itemInfo.preamble"></div>
        </a>
    </li>
</template>

<script setup lang="ts">
import { NavBlockItem } from "../../types";
export interface Props {
    isListView: boolean,
    itemInfo: NavBlockItem
}

const props = defineProps<Props>();
</script>
