<template>
    <span>
        <RouterLink to="/">Tillbaka till forumet</RouterLink>
    </span>
    <div>
        <div v-if="messagesPaginated?.topicIncludingMessage?.topic.userCanEditTopic" class="admin-btns">
            <button class="btn btn-outline-primary btn-small" type="button"
                @click="currentMsg = messagesPaginated.topicIncludingMessage.message; showEditModal = true; editThread = true">
                {{ texts.forum.editMessage }}</button>
            <button class="btn btn-outline-primary btn-small" type="button"
                @click="currentMsg = messagesPaginated.topicIncludingMessage.message; showDeleteModal = true; editThread = true">
                {{ texts.forum.delete }} </button>
        </div>
        <div class="mainTopic d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
                <div class="fw-bold">
                    {{ messagesPaginated?.topicIncludingMessage?.topic?.userDisplayName }}
                    <span class="time">{{ formatDate(messagesPaginated?.topicIncludingMessage?.topic?.posted) }}</span>
                    <span v-if="messagesPaginated.topicIncludingMessage.message.edited"
                        class="edit-badge badge bg-primary"> {{
            texts.forum.isedited }} </span>
                </div>
                <h2>{{ messagesPaginated?.topicIncludingMessage?.topic?.topicName }}</h2>
                <div class="message" v-html="messagesPaginated?.topicIncludingMessage?.message.messageText"></div>
                <ul v-if="messagesPaginated?.topicIncludingMessage?.message.attachments.length" class="file-preview">
                    <li v-for="attachment in messagesPaginated?.topicIncludingMessage?.message.attachments">
                        <a :href="'/api/yaf/downloadattachment/' + attachment.id" :class="attachmentClass(attachment)">
                            <img v-if="isImage(attachment)" :src="'/api/yaf/previewattachment/' + attachment.id"
                                class="thumb">
                            <span>{{ attachment.fileName }} ({{ prettySize(attachment.bytes) }})</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


        <CreateMessage :replyToId="replyTo" :buttonText="texts.forum.answerthread" @loadMessages="fetchMessages" />
        <EditMessage :message="currentMsg" :isVisible="showEditModal" :editThread=editThread
            :topicName=messagesPaginated?.topicIncludingMessage?.topic?.topicName @loadMessages="fetchMessages"
            @closeEditModal="cancelEditMsg"></EditMessage>
        <div v-if="showDeleteModal" class="modal-deleteMsg" @keydown="handleTabEvent($event, 'modal-content')">
            <div class="modal-content">
                <p v-if="editThread"> Är du säker på att du vill radera detta inlägg? </p>
                <p v-else>{{ texts.forum.deletetext }} </p>
                {{ status_message }}
                <button v-if="editThread" @click="confirmDeleteTopic()" class="btn btn-primary confirm"
                    ref="modalConfirm"> Ta bort tråd </button>
                <button v-else @click="confirmDeleteMsg(currentMsg)" class="btn btn-primary confirm"
                    ref="modalConfirm">{{
            texts.forum.deleteConfirm }}</button>
                <button @click="cancelDeleteMsg" class="btn btn-outline-primary btn-small">{{ texts.forum.cancel
                    }}</button>
            </div>
        </div>

        <ul class="list-group messages">
            <li class="list-group-item d-flex justify-content-between align-items-start"
                v-for="(item, index) in replies?.filter(i => !i.isDeleted)" :key="index">
                <div class="ms-2 me-auto comment-container">
                    <div class=" comment-header">

                        <div class="fw-bold comment-info-container">
                            {{ item.userDisplayName }}
                            <span class="time" v-if="item.posted">({{ formatDate(item.posted) }})</span>
                            <span v-if="item.edited" class="edit-badge badge bg-primary"> {{
            texts.forum.isedited }} </span>

                        </div>
                        <div v-if="item.userCanEditMsg">
                            <button class="btn btn-outline-primary btn-small" style="margin-top:0;" type="button"
                                @click="currentMsg = item; showEditModal = true; editThread = false"> {{
            texts.forum.editMessage }}</button>
                            <button class="btn btn-outline-primary btn-small" style="margin-top:0;" type="button"
                                @click="currentMsg = item; showDeleteModal = true; editThread = false"> {{
            texts.forum.delete }} </button>
                        </div>
                    </div>

                    <div v-if="isReply(item)" class="reply">
                        {{ texts.forum.answerto }} <b> {{ item.replyDisplaName }}</b> <br />
                        <span v-if="!item.replyToIsDeleted" v-html="item.replyText"></span>
                        <em v-else>{{ texts.forum.messagehasbeendeleted }}</em>
                    </div>
                    <div v-html="item.messageText"></div>
                    <ul v-if="item.attachments.length" class="file-preview">
                        <li v-for="attachment in item.attachments">
                            <a :href="'/api/yaf/downloadattachment/' + attachment.id">
                                <span>{{ attachment.fileName }} ({{ prettySize(attachment.bytes) }})</span>
                            </a>
                        </li>
                    </ul>
                    <div>
                        <CreateMessage :replyToId="item.id" :buttonText="'Svara'" @loadMessages="fetchMessages" />
                    </div>
                </div>

                <span class="badge bg-primary">{{ item.position }}</span>

            </li>
        </ul>
        <div class="result-paging col-xs-12"
            v-if="messagesPaginated.paginatedList != null && messagesPaginated.paginatedList.length > 0">
            <PaginationComp :pagination="messagesPaginated.pagination" @handlePageChange="handlePageChange">
            </PaginationComp>
        </div>
    </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch, nextTick, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { Message, GetMessagesParams, MessagesPaginated, Attachment, DeleteMessageParams, DeleteTopicParams } from '../../YafTypes';
import { loadMessagesWithPagination } from '../../services/YafService';
import { deleteMessage, deleteTopic } from '../../services/YafService';
import { handleTabEvent } from '../../utils';
import { timeAgoUntil } from "../../utils";
import CreateMessage from './CreateMessage.vue';
import EditMessage from './EditMessage.vue';
import PaginationComp from '../Pagination.vue';

const texts = computed(() => uhr.localizedTexts);

const router = useRouter();
const route = useRoute();
const showEditModal = ref(false);
const showDeleteModal = ref(false);
const editThread = ref(false);

const props = defineProps({
    topicid: Number,
    heading: String
});
var currentMsg: Message = { id: 0, attachments: [] };
const topicId = ref(0);
const replyTo = ref(0);
const status_message = ref('');
const modalConfirm = ref<HTMLInputElement | null>(null);
const messagesPaginated = ref<MessagesPaginated>({
    topicIncludingMessage: { message: { id: 0, attachments: [] }, topic: { topicId: 0 } },
    paginatedList: [],
    pagination: {
        numberOfPages: 1,
        currentPage: 1
    }
});

watch(showDeleteModal, (newValue: any) => {
    if (newValue) {
        // Modal is shown, set focus on the first button
        nextTick(() => {
            if (modalConfirm.value) {
                modalConfirm.value.focus();
            }
        });
    }
}
);

const isReply = (m: Message) => {
    return m.replyTo && m.replyText;
}

const prettySize = (s: number) => {
    if (s > 1000000) {
        return Math.round(s / 100000) / 10 + 'Mb';
    }
    if (s > 1000) {
        return Math.round(s / 100) / 10 + 'kb';
    }

    return s + 'b';
}


const confirmDeleteMsg = (message?: Message) => {
    if (message != null) {

        const deleteMessageParams: DeleteMessageParams = {
            messageId: message.id,
        };
        deleteMessage(deleteMessageParams).then(() => {
            showDeleteModal.value = false; // Close the modal
            fetchMessages();

        })
            .catch(error => {
                console.error("Ett fel uppstod, kunde inte radera meddelande", error);
                status_message.value = "Failed to delete message";
            });
    }

}

const confirmDeleteTopic = () => {
    const deleteTopicParams: DeleteTopicParams = {
        topicId: topicId.value,
    };
    deleteTopic(deleteTopicParams).then(() => {
        showDeleteModal.value = false; // Close the modal
        fetchMessages();

    }).then(() => {
        goToTopicList();
    })
        .catch(error => {
            console.error("Ett fel uppstod, kunde inte radera meddelande", error);
            status_message.value = "Failed to delete message";
        });
}

const goToTopicList = () => {

    router.push({ path: '/', replace: true })

};

const cancelEditMsg = () => {
    showEditModal.value = false; // Close the modal

};

const cancelDeleteMsg = () => {
    showDeleteModal.value = false; // Close the modal
    status_message.value = ""; // Reset status message

};

const formatDate = (d: string | undefined) => {
    if (d) {
        const date = new Date(d);
        return timeAgoUntil(date);
    }
    return '';
}

const isImage = (a: Attachment): boolean => {
    return a.contentType?.toLocaleLowerCase().startsWith('image');
}


const attachmentClass = (a: Attachment): string => {
    const x = a.contentType.toLocaleLowerCase().split('/');
    return "attachment " + x[0];
};

const replies = ref<Message[]>();

function handlePageChange(pagenumber: number) {
    router.push({
        path: '/topic/' + topicId.value + '/' + pagenumber
    });
}

async function fetchMessages() {
    const GetMessageParams: GetMessagesParams = {
        topicId: props.topicid!,
        page: messagesPaginated.value.pagination.currentPage,
        pageSize: uhr.forum.forumHits
    };

    if (topicId) {
        try {
            messagesPaginated.value = await loadMessagesWithPagination(GetMessageParams);
            replies.value = messagesPaginated.value.paginatedList;
            replyTo.value = messagesPaginated.value.topicIncludingMessage.message?.id;
        } catch (error) {
            console.error("Failed to load messages", error);
        }
    }
}

const setPage = (page: number) => {
    messagesPaginated.value.pagination.currentPage = page;
    fetchMessages();
};

router.afterEach(x => {
    var page = parseInt(<string>x.params.page);
    if (isNaN(page) || page < 1) {
        page = 1;
    }
    setPage(page);
});

onMounted(async () => {
    topicId.value = props.topicid ? props.topicid : 0;

    var page = parseInt(<string>route.params.page);
    if (isNaN(page) || page < 1) {
        page = 1;
    }

    setPage(page);
});

</script>