<template>
  <Accordion :items="items" :heading="heading" :preamble="preamble" :search-text="searchText" :show-read-more="showMore"
    @load-more="getAllQuestionsOnButtonClick" />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import api from "../../services/SHServices";
import { QuestionItem } from "../../types";
import Accordion from './Accordion.vue';

const props = defineProps({
  ContentID: Number
});

const items = ref<Array<QuestionItem>>([]);
const heading = ref<string>('');
const preamble = ref<string>('');
const searchText = ref<string>('');
const showMore = ref<boolean>(false);


onMounted(async () => {
  api.getAccordionData(props.ContentID ?? 0).then(result => {
    heading.value = result.heading;
    preamble.value = result.preamble;
    searchText.value = result.searchText;
    showMore.value = result.questionListSize > 10;
    items.value = result.questionList;
  });
});

function getAllQuestionsOnButtonClick() {
  api.getAccordionData(props.ContentID ?? 0, true).then(result => {
    items.value = result.questionList;
    showMore.value = false;
  });
}
</script>
