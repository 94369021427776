<template>
    <SearchField v-model="query" role="search" :placeholder=texts.miniSearch.sokPaWebbplatsen @search-click="search"
        :uniqueID="props.innerId" />
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { getHashAsDictionary, setHashKey } from '../../utils';
import SearchField from '../SearchField.vue';

const props = defineProps({
    innerId: {
        type: String,
        default: "focus-search"
    }
})
const query = ref('');
const texts = computed(() => uhr.localizedTexts);
const search = () => {
    if (uhr.page.isSearchPage) {
        setHashKey('query', query.value);
    } else {
        document.location.href = uhr.settings.searchPage + '#query=' + query.value;
    }
};

const readParametersFromHash = () => {
    const p = getHashAsDictionary();
    if (p.isEmpty)
        return;

    query.value = decodeURIComponent(p.query);
};

window.addEventListener('popstate', readParametersFromHash);

onMounted(async () => {
    readParametersFromHash();
});

</script>
