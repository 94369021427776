<template>
    <div class="event-search">
        <div class="container search-container">
            <div class="col-12 col-lg-6 offset-lg-3 mb-4 pb-2">
                <SearchField :placeholder=texts.searchField.sokEvenemang v-model="p.query" @search-click="search" />
            </div>
        </div>
        <button class="filter-toggle mobile-only collapse-button collapsed" data-bs-toggle="collapse"
            data-bs-target="#filter" aria-expanded="false" aria-controls="filter">
            <span class="material-icons-sharp material-icons-sharp-tune" role="img"
                :aria-label="texts.eventSearch.filtrera"></span>
            <h2 class="filter-header">{{ texts.eventSearch.filtrera }}</h2>
            <span class="material-icons-sharp dropdown"></span>
        </button>
        <div class="container filter collapse" id="filter">
            <div class="d-lg-flex w-100 pb-2 header desktop-only">
                <span class="material-icons-sharp material-icons-sharp-tune" role="img"
                    :aria-label=texts.eventSearch.filtrera></span>
                <h2 class="filter-header">{{ texts.eventSearch.filtrera }}</h2>
            </div>

            <div class="toolbar row">
                <div class="filter col-12 col-lg-4">
                    <fieldset>

                        <legend class="filterLegend">{{ texts.eventSearch.amnesomrade }}</legend>
                        <div class="dropdown" onclick="event.stopPropagation()">
                            <button class="btn btn-filter dropdown-toggle" type="button" id="ddCategory"
                                data-bs-toggle="dropdown" data-bs-offset="0,0" aria-expanded="false">
                                {{ texts.eventSearch.valjAmnesomrade }}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="ddCategory">
                                <li v-for="(f, index) in filterData?.category" :key="index">
                                    <div class="form-check-container">
                                        <input class="form-check-input" v-model="p.category" type="checkbox"
                                            v-on:change="search" :value="f.key" :id="'categoryCb-' + f.key">
                                        <label class="form-check-label" :for="'categoryCb-' + f.key">
                                            {{ f.name }}
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </fieldset>
                </div>
                <div class="filter col-12 col-lg-4">
                    <fieldset>
                        <legend class="filterLegend">{{ texts.eventSearch.plats }}</legend>
                        <div>
                            <div class="dropdown">

                                <button class="btn btn-filter dropdown-toggle w-100" type="button" id="locationCb"
                                    data-bs-toggle="dropdown" data-bs-offset="0,0" aria-expanded="false">
                                    {{ texts.eventSearch.valjPlats }}
                                </button>
                                <ul class="dropdown-menu" onclick="event.stopPropagation()"
                                    aria-labelledby="locationCb">
                                    <li>
                                        <div class="form-check-container">
                                            <input class="form-check-input" v-model="p.digital" type="checkbox"
                                                v-on:change="search" value="" id="digitalCb">
                                            <label class="form-check-label" for="digitalCb">
                                                {{ texts.eventSearch.paDistansDigitalt }}
                                            </label>
                                        </div>
                                    </li>
                                    <div class="dropdown-plats-separator "></div>
                                    <label class="dropdown-inner-label">
                                        {{ texts.eventSearch.paPlatsFysiskt }}
                                    </label>
                                    <li v-for="(f, index) in filterData?.location" :key="index">
                                        <div class="form-check-container">
                                            <input class="form-check-input" v-model="p.location" type="checkbox"
                                                v-on:change="search" :value="f.key" :id="'locationCb' + f.key">
                                            <label class="form-check-label" :for="'locationCb' + f.key">
                                                {{ f.name }}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div class="filter col-12 col-lg-4">
                    <fieldset>
                        <legend class="filterLegend"> {{ texts.eventSearch.tidpunkt }} </legend>
                        <div class="flex-columns">
                            <div class="col form-check-container">
                                <input class="form-check-input" v-model="p.day" type="checkbox" v-on:change="search"
                                    value="" id="dayCb">
                                <label class="form-check-label" for="dayCb">
                                    {{ texts.eventSearch.dagtid }}
                                </label>
                            </div>
                            <div class="col form-check-container">
                                <input class="form-check-input" v-model="p.night" type="checkbox" v-on:change="search"
                                    value="" id="nightCb">
                                <label class="form-check-label" for="nightCb">
                                    {{ texts.eventSearch.kvallstid }}
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <button v-if="hasFilters()" class="btn btn-outline-primary clear-button desktop-only"
                        @click="clearFilters">{{ texts.eventSearch.rensafilter }}</button>
                </div>
            </div>
        </div>

        <div class="results bg-vanilla">
            <div class="container">
                <h2 class="count" aria-live="polite">{{ results?.totalMatching || texts.eventSearch.ingaAktiviteter }}
                    {{ texts.eventSearch.kommandeAktiviteter }}</h2>
                <div v-if="hasFilters()" class="clear-container">
                    <button v-for="(x, index) in getSelectedFilters()" :key="index" @click="x.clearFn"
                        :class="['btn clear-button ', x.extraClass]">
                        <span class="hide">Ta bort filtret</span>{{ x.value }}
                    </button>
                </div>
                <div v-if="results?.totalMatching > 0" class="event-list row row-cols-1 row-cols-md-3 g-3">
                    <template v-for="(item, index) in results?.hits">
                        <div class="col d-flex">
                            <EventListItemComponent :itemInfo="item" :key="index" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';

import EventListItemComponent from './EventListItem.vue';
import api, { SearchEventParams } from "../../services/SHServices";
import { EventSearchResult, Facet } from '../../types';
import SearchField from '../SearchField.vue';
import { debounce } from '../../utils';

const texts = computed(() => uhr.localizedTexts);
const startDate = new Date(new Date().toDateString());
const defaultFilter = {
    query: '',
    digital: false,
    local: false,
    startDate: startDate,
    range: [startDate, null],
    day: false,
    night: false,
    category: [],
    take: 0,
    location: [],
    root: uhr.settings.eventRoot,
    lang: uhr.page.lang
};

//ref seems to modify the constant so ensure it is cloned before use so it can be used to reset later
const p = ref<SearchEventParams>({ ...defaultFilter });

type filterDataType = { category: Array<Facet>, location: Array<Facet> };

const results = ref<EventSearchResult>({ totalMatching: 0, facets: { categories: [], location: [] }, hits: [] });
const filterData = ref<filterDataType>({ category: [], location: [] });

const performSearch = () => {
    api.searchEvents(p.value)
        .then(r => {
            results.value = r;
        });
};

const loadInitialValues = () => {
    var c = { ...defaultFilter };
    c.take = 1;
    api.searchEvents(c)
        .then(r => {
            results.value = r;
            filterData.value.category = r.facets.categories;
            filterData.value.location = r.facets.location;
        });
};

const search = debounce(performSearch, 100);

const clearFilters = () => {
    Object.getOwnPropertyNames(defaultFilter).forEach(prop => {
        (<any>p.value)[prop] = (<any>defaultFilter)[prop];
    });
    search();
};

const isFilter = (prop: string): boolean => {
    var v = (<any>filterData.value)[prop] as Array<Facet>;
    return v != undefined;
};

const findFilterName = (prop: string, key: any) => {
    var v = (<any>filterData.value)[prop] as Array<Facet>;
    if (v) {
        var a = v.filter(f => f.key == key);
        if (a && a.length > 0) {
            return a[0].name;
        }
    }

    return 'MISSING: ' + key;
};

const translateProp = (prop: string, key: any) => {
    if (prop === 'local') {
        return 'På plats';
    } else if (prop === 'digital') {
        return 'Digitalt';
    } else if (prop === 'day') {
        return 'Dagtid kl 9-17';
    } else if (prop === 'night') {
        return 'Kvällstid kl 17-21';
    } else if (prop === 'range') {
        return (<Date>key[0]).toISOString().substring(0, 10) + " - " + (<Date>key[1]).toISOString().substring(0, 10);
    } else if (typeof key?.getMonth === 'function') {
        return (<Date>key).toISOString().substring(0, 10);
    }

    return key;
}

const hasFilters = () => {
    return getSelectedFilters()?.length > 0;
}

const getSelectedFilters = () => {
    var filters = Object.getOwnPropertyNames(defaultFilter).reduce((acc, prop) => {
        const defaultFilterProp = (<any>defaultFilter)[prop];
        const selectedFilterProp = (<any>p.value)[prop];

        const isArray = !!defaultFilterProp && typeof defaultFilterProp === 'object' && defaultFilterProp.length !== undefined;
        if (isArray && isFilter(prop)) {
            if (selectedFilterProp.length > 0) {
                (<Array<string>>selectedFilterProp).forEach(val => {
                    acc.push({
                        name: prop,
                        value: findFilterName(prop, val),
                        clearFn: () => {
                            (<any>p.value)[prop] = (<Array<string>>(<any>p.value)[prop]).filter(v => v != val);
                            search();
                        }
                    });
                });
            }
        } else {
            if (selectedFilterProp != defaultFilterProp && !(isArray && arrayEquals(selectedFilterProp, defaultFilterProp))) {
                acc.push({
                    name: prop,
                    value: translateProp(prop, selectedFilterProp),
                    clearFn: () => {
                        (<any>p.value)[prop] = defaultFilterProp;
                        search();
                    }
                });
            }
        }

        return acc;
    }, <Array<any>>[]);

    if (filters && filters.length) {
        filters.push({
            name: "clear-all",
            value: "Rensa alla filter",
            extraClass: 'mobile-only',
            clearFn: clearFilters
        });
    }

    return filters;
};


const arrayEquals = (a: Array<any>, b: Array<any>): boolean => {
    if (a.length != b.length)
        return false;

    return a.map((v, i) => v === b[i]).reduce((p, c) => p && c, true);
}

onMounted(async () => {
    loadInitialValues();
    //TODO: read hash? 
    search();
});
</script>