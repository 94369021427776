const parser = new DOMParser();

export function capitalizeFirstLetter(str: string): string | undefined {
	if (!str) return;
	let strArr = str.split(" ");
	for (let i = 0; i < strArr.length; i++) {
		strArr[i] = strArr[i][0].toUpperCase() + strArr[i].substring(1);
	}
	return strArr.join(" ");
}

export function removeParagraphTags(str: string): string {
	var regex = /(<p[^>]+?>|<p>|<\/p>)/gim;
	var result = str.replace(regex, "");
	return result;
}

export function convertDate(date: string): string {
	return new Date(date).toLocaleDateString("sv-SE", {
		year: "numeric",
		month: "long",
		day: "numeric",
	});
}

// Function counts the characters in the text nodes while ignoring tags.
// Also ensure that tags are properly closed after truncating text and avoid empty tags.
export function shortenHtml(input: string, maxChars: number = 400): string {
	if (!input || maxChars <= 0) return "";

	// Parse the input HTML string into a DOM Document
	const doc = parser.parseFromString(input, "text/html");

	let charCount = 0;
	let result = "";

	// Helper function to traverse the nodes
	const traverseNodes = (node: ChildNode): string => {
		let result = "";

		if (node.nodeType === Node.TEXT_NODE) {
			// Handle text nodes
			const textContent = node.textContent || "";
			const remainingChars = maxChars - charCount;
			if (charCount + textContent.length > maxChars) {
				// Truncate text and return
				result += textContent.substring(0, remainingChars);
				charCount += remainingChars;
			} else {
				result += textContent;
				charCount += textContent.length;
			}
		} else if (node.nodeType === Node.ELEMENT_NODE) {
			// Handle element nodes
			const element = node as HTMLElement;
			const tagName = element.tagName.toLowerCase();

			// Open tag
			result += `<${tagName}${getAttributesString(element)}>`;

			// Traverse children recursively
			for (let i = 0; i < element.childNodes.length; i++) {
				const childResult = traverseNodes(element.childNodes[i]);
				result += childResult;
				if (charCount >= maxChars) break;
			}

			// Close tag if there was valid content
			if (result.trim() !== `<${tagName}>`) {
				result += `</${tagName}>`;
			}
		}

		return result;
	};

	// Function to get element's attributes as a string
	const getAttributesString = (element: HTMLElement): string => {
		const attrs = Array.from(element.attributes);
		return attrs.map((attr) => ` ${attr.name}="${attr.value}"`).join("");
	};

	// Traverse the root document body
	for (let i = 0; i < doc.body.childNodes.length; i++) {
		result += traverseNodes(doc.body.childNodes[i]);
		if (charCount >= maxChars) break;
	}

	return result;
}

export function decodeHtml(encodedStr: string) {
	return parser.parseFromString(encodedStr, "text/html").documentElement
		.textContent;
}

export function setupClickableAreas(classname: string) {
	// Handle both click and keyboard events on .clickableArea
	const clickableAreas = document.querySelectorAll<HTMLElement>(
		"." + classname
	);

	clickableAreas.forEach((clickableArea) => {
		const handleInteraction = (event: MouseEvent | KeyboardEvent) => {
			const link = clickableArea.querySelector<HTMLAnchorElement>("a");

			// Handle click or keypress (Enter or Space)
			if (link && link.href.length > 1) {
				if (
					event instanceof MouseEvent ||
					(event instanceof KeyboardEvent &&
						(event.key === " " || event.key === "Enter"))
				) {
					event.preventDefault();
					if (link.target) {
						window.open(link.href, link.target);
					} else {
						window.location.href = link.href;
					}
				}
			}
		};

		// Add click event listener
		clickableArea.addEventListener("click", handleInteraction);

		// Add keyboard event listener (for Space and Enter keys)
		clickableArea.addEventListener("keydown", (event: KeyboardEvent) => {
			if (event.key === " " || event.key === "Enter") {
				handleInteraction(event);
			}
		});
	});
}

export function convertEventDate(startDate: string, endDate = "") {
	const months = [
		"jan",
		"feb",
		"mar",
		"apr",
		"maj",
		"jun",
		"jul",
		"aug",
		"sep",
		"okt",
		"nov",
		"dec",
	];

	const start = new Date(startDate);
	const end = endDate ? new Date(endDate) : null;

	const startYear = start.getFullYear();
	const startMonth = months[start.getMonth()];
	const startDay = start.getDate();
	const startHour = String(start.getHours()).padStart(2, "0");
	const startMinute = String(start.getMinutes()).padStart(2, "0");

	if (end) {
		const endYear = end.getFullYear();
		const endMonth = months[end.getMonth()];
		const endDay = end.getDate();
		const endHour = String(end.getHours()).padStart(2, "0");
		const endMinute = String(end.getMinutes()).padStart(2, "0");
		if (startYear === endYear && startMonth === endMonth) {
			if (startDay === endDay) {
				return `${startDay} ${startMonth}, kl ${startHour}:${startMinute}-${endHour}:${endMinute}`;
			} else {
				return `${startDay} - ${endDay} ${endMonth}`;
			}
		} else {
			return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
		}
	}
	return `${startDay} ${startMonth}, kl ${startHour}:${startMinute}`;
}

export function firstOrDefault<T extends any>(
	list: Array<T>,
	filter: (i: T) => boolean
): T | null {
	var filtered = list?.filter(filter);
	return filtered && filtered.length ? filtered[0] : null;
}

interface Dictionary {
	[key: string]: string;
}

export const getHashAsDictionary = (): Dictionary => {
	const arr = document.location.hash?.substring(1).split("&");

	if (!arr || !arr.length || !document.location.hash) return { isEmpty: "1" };

	return Object.assign(
		{},
		...arr.map((x) => {
			const a = x.split("=");
			return { [a[0]]: decodeURIComponent(a[1]) };
		})
	);
};

export const convertDictionaryToHashString = (dict: Dictionary): string => {
	let val = "";
	Object.getOwnPropertyNames(dict).forEach((p) => {
		val += p + "=" + encodeURIComponent(dict[p]) + "&";
	});

	if (val.length > 0) {
		//trim trailing &
		val = val.substring(0, val.length - 1);
	}
	return val;
};

export const setHashFromDictionary = (dict: Dictionary): void => {
	const val = convertDictionaryToHashString(dict);
	document.location.hash = "#" + val;
};

export const setHashKey = (key: string, value: string): void => {
	var dict = getHashAsDictionary();
	if (dict.isEmpty) dict = {};
	dict[key] = value;
	setHashFromDictionary(dict);
};

export const debounce = function debounce(
	fn: Function,
	waitMilliseconds: number
) {
	let timer: number;
	return function (this: any, ...args: any[]) {
		if (timer) {
			clearTimeout(timer);
		}
		const context = this;
		timer = window.setTimeout(() => {
			fn.apply(context, args);
		}, waitMilliseconds);
	};
};

export const applyGlobalAnchorStyles = () => {
	var allA = <NodeListOf<HTMLAnchorElement>>(
		document.querySelectorAll("a:not(.marked):not(.external)")
	);
	allA.forEach((a) => {
		if (isExternalLink(a.href)) {
			a.classList.add("external");
			a.classList.add("marked");
		}
	});

	allA = <NodeListOf<HTMLAnchorElement>>(
		document.querySelectorAll("a:not(.marked):not(.internal)")
	);

	allA.forEach((a) => {
		if (isInternalLink(a.href)) {
			a.classList.add("internal");
			a.classList.add("marked");
		}
	});
};

export const isExternalLink = (url: string) => {
	return (
		url && new URL(url, window.location.href).origin !== window.location.origin
	);
};

export const isInternalLink = (url: string) => {
	return !isExternalLink(url);
};

export const handleTabEvent = (event: KeyboardEvent, modalclass: string) => {
	if (event.key === "Tab") {
		const modal = document.querySelector("." + modalclass) as HTMLElement;
		if (modal) {
			const focusableElements = Array.from<HTMLElement>(
				modal.querySelectorAll(
					'button:not([disabled]), [href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
				)
			);

			const firstElement = focusableElements[0];
			const lastElement = focusableElements[focusableElements.length - 1];

			if (event.shiftKey) {
				// If Shift+Tab and focus is on the first element, move to the last
				if (document.activeElement === firstElement) {
					event.preventDefault();
					lastElement.focus();
				}
				if (
					document.activeElement &&
					document.activeElement.classList.contains("ck-editor__editable")
				) {
					event.preventDefault();
					firstElement.focus();
				}
			} else {
				// If Tab and focus is on the last element, move to the first
				if (document.activeElement === lastElement) {
					event.preventDefault();
					firstElement.focus();
				}
			}
		}
	}
};

//Plockad och refaktoriserad från nice-date package
export const timeAgoUntil = (date: Date): string => {
	const now = new Date();
	const future = now < date;
	const msg = future ? "from now" : "ago";

	const diffMs = Math.abs(now.getTime() - date.getTime());
	const diffSec = Math.floor(diffMs / 1000); // Convert milliseconds to seconds
	const diffMin = Math.floor(diffSec / 60);
	const diffHr = Math.floor(diffSec / 3600);
	const diffDay = Math.floor(diffSec / (24 * 3600));
	const diffWeek = Math.floor(diffDay / 7);
	const diffMonth = Math.floor(diffDay / 30);
	const diffYear = Math.floor(diffMonth / 12);

	if (diffSec < 5) {
		return "just now";
	}

	if (diffYear > 0) {
		return `${diffYear} year${diffYear !== 1 ? "s" : ""} ${msg}`;
	} else if (diffMonth > 0) {
		return `${diffMonth} month${diffMonth !== 1 ? "s" : ""} ${msg}`;
	} else if (diffWeek > 0) {
		return `${diffWeek} week${diffWeek !== 1 ? "s" : ""} ${msg}`;
	} else if (diffDay > 0) {
		return diffDay > 1
			? `${diffDay} day${diffDay !== 1 ? "s" : ""} ${msg}`
			: future
			? "tomorrow"
			: "yesterday";
	} else if (diffHr > 0) {
		return `${diffHr} hour${diffHr !== 1 ? "s" : ""} ${msg}`;
	} else if (diffMin > 0) {
		return `${diffMin} minute${diffMin !== 1 ? "s" : ""} ${msg}`;
	} else {
		return `${diffSec} second${diffSec !== 1 ? "s" : ""} ${msg}`;
	}
};

export const formatDate = (d: string) => {
	if (d) {
		const date = new Date(d);
		return timeAgoUntil(date);
	}
	return "";
};
