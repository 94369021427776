<template>
    <div v-if="isVisible">

        <div class="modal-editMsg" @keydown="handleTabEvent($event, 'modal-content')">
            <div class="modal-content">
                <div class="messageEditor" :id="editorName()">
                    <div v-if="props.editThread">
                        <label>Redigera rubrik:</label>
                        <input class="form-control topic-textinput" type="text"
                        v-model="inputTopic" :maxlength="maxLength"></input>
                    </div>
                    <label>{{ texts.forum.editMessageText }}</label>
                    <CKEditor :editorData="editorData" @update:editorData="updateEditorData" class="topic-editor" />
                    <br />
                    <label for="upload-file">{{ texts.forum.files }}</label><br />
                    <!-- Lista på filer -->
                    <ul v-if="props.message?.attachments.length" class="file-preview">
                        <li v-for="attachment in props.message?.attachments">
                            <div v-if="!filesToRemove.includes(attachment.id)" class="attachmentList">
                                <a :href="'/api/yaf/downloadattachment/' + attachment.id">
                                    <img v-if="isImage(attachment)" :src="'/api/yaf/previewattachment/' + attachment.id"
                                        class="thumb">
                                    <span>{{ attachment.fileName }} ({{ prettySize(attachment.bytes) }})</span>
                                </a>
                                <button :aria-label="'Ta bort fil ' + attachment.fileName"
                                    class="badge btn btn-primary btn-small" type="button"
                                    @click="removeAttachment(attachment)">x</button>
                            </div>
                            <div v-if="filesToRemove.includes(attachment.id)"
                                class="attachmentList attachmentListRemoved">
                                <span>
                                    <img v-if="isImage(attachment)" :src="'/api/yaf/previewattachment/' + attachment.id"
                                        class="thumb">
                                    <span> {{ attachment.fileName }} ({{ prettySize(attachment.bytes) }})</span>
                                </span>
                                <button class="badge btn btn-primary btn-small disabled" type="button">x</button>
                            </div>
                        </li>
                    </ul>
                    <input id="upload-file" type="file" ref="upload" multiple>
                </div>
                <button id="saveMessage" class="btn btn-primary btn-small" type="button" @click="sendData"
                    data-bs-toggle="collapse" :data-bs-target="editorName(true)" aria-expanded="false"
                    :aria-controls="editorName()">
                    {{ texts.forum.saveBtn }}
                </button>
                <button ref="modalEdit" id="cancelEditMessage" class="btn btn-outline-primary btn-small"
                    @click="handleCancel" type="button" data-bs-toggle="collapse" :data-bs-target="editorName(true)"
                    aria-expanded="false" :aria-controls="editorName()">
                    {{ texts.forum.cancel }}
                </button>
            </div>
        </div>
        <p id="statusMsg">{{ status_message }}</p>
    </div>

</template>

<script setup lang="ts">
import { ref, PropType, watch, nextTick, computed} from 'vue'
import CKEditor from './Editor.vue';
import { editMessage } from '../../services/YafService';
import { Message, Attachment, EditMessageParams } from "../../YafTypes";
import { handleTabEvent } from '../../utils';

const texts = computed(() => uhr.localizedTexts);
const props = defineProps({
    message: { type: Object as PropType<Message> },
    isVisible: Boolean,
    editThread: Boolean,
    topicName: String
});

const status_message = ref('');
const editorData = ref<string | undefined>('');
const upload = ref<HTMLInputElement | null>(null);
const inputTopic = ref<string | undefined>('');
const maxLength = ref(100);
const filesToRemove = ref(<Array<number>>[]);
const modalEdit = ref<HTMLInputElement | null>(null);


const emits = defineEmits(['loadMessages', 'closeEditModal']);

const editorName = (prependHash = false) => (prependHash ? '#' : '') + 'editor_' + props.message?.id + "edit";

function updateEditorData(newData: string) {
    editorData.value = newData;
}

let focusableElements: HTMLElement[] = [];

watch(() => props.isVisible, async (newValue) => {
    if (newValue) {

        await nextTick();
        await new Promise(resolve => setTimeout(resolve, 0));
        inputTopic.value = props.topicName;
        editorData.value = props.message?.messageText;
        let modal = document.querySelector('.modal-content');

        if (modal) {
            focusableElements = Array.from<HTMLElement>(
                modal.querySelectorAll(
                    'button:not([disabled]), [href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
                )
            );
            focusableElements[0].focus();
        }
    }
});

function sendData() {
    if (props.message != null) {

        const htmlValue = editorData.value;
        const newThreadName = inputTopic.value;
        const editMessageParams: EditMessageParams = {
            messageId: props.message.id,
            newMessage: htmlValue?.toString(),
            threadTopic: newThreadName?.toString(),
            filesToRemove: filesToRemove.value
        };

        editMessage(editMessageParams, upload.value)
            .then(() => {
                emits('closeEditModal');
                emits('loadMessages'); // Load new messages after sending
                if (upload.value) {
                    upload.value.value = '';
                }
            })
            .catch(error => {
                console.error("Ett fel uppstod, kunde inte skapa nytt meddelande", error);
                status_message.value = "Failed to create message";
            });
        filesToRemove.value = [];
    }
}

const handleCancel = () => {
    // Emit the close event
    emits('closeEditModal');

    // Clear the selected files
    if (upload.value) {
        upload.value.value = '';
    }

    // Clear any files to be removed
    filesToRemove.value = [];
};

function removeAttachment(a: Attachment) {
    filesToRemove.value.push(a.id);
}

const isImage = (a: Attachment): boolean => {
    return a.contentType?.toLocaleLowerCase().startsWith('image');
}


const prettySize = (s: number) => {
    if (s > 1000000) {
        return Math.round(s / 100000) / 10 + 'Mb';
    }
    if (s > 1000) {
        return Math.round(s / 100) / 10 + 'kb';
    }

    return s + 'b';
}

</script>

<style>
.attachmentList {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.attachmentList .btn {
    margin: 0;
}

.messageEditor {
    margin-top: 10px;

}

.topic-textinput,
#topicForm {
    margin-bottom: 10px;
}

.topicForm-buttons {
    margin-bottom: 10px;
}

#messageForm {
    margin-top: 10px;
}

.topic-editor {
    width: 48rem;
    height: 50%;

}

.modal-editMsg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-editMsg .modal-content {
    overflow: scroll;
    max-height: 48rem;
    max-width: 48rem;
    background-color: white;
    padding: 20px;
    border-radius: 5px;

}

.confirm {
    margin-bottom: 0.5rem;
    margin-right: 0;
}

.attachmentListRemoved {
    opacity: 50%;
}
</style>