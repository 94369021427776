<template >
    <a v-for="index in props.antalslides" role="button" 
        :class="props.activeNumber === index ? 'active' : ''"
        :aria-current="props.activeNumber === index" 
        :aria-label="'Evenemang '.concat((index).toString())"
        :data-bs-slide-to="index-1"
        :data-bs-target="props.carouselId" 
        >&nbsp;</a>

</template>

<script setup lang="ts">
    export interface Props {
        antalslides: number;
        activeNumber: number;
        carouselId: string;
    }
    const props = defineProps<Props>()
</script>