<template>
    <div class="search">
        <div class="search-container flex-nowrap">
            <SearchField v-model="query" :placeholder=texts.search.sokPaWebbplatsen @search-click="setQuery" />
        </div>
        <div class="filter-container">
            <div v-if="filters">
                <label class="category-label" for="search-filters">{{ texts.search.taggning }}</label>
                <select id="search-filters" v-if="filters" class="btn btn-filter dropdown-toggle"
                    @change="e => setFilter(e)" v-model="selected">
                    <option :class="'dropdown-item' + (selectedFilter == '' ? ' active' : '')" value="" selected>{{
                        texts.search.alla }} ({{ results?.totalMatching }})</option>
                    <option v-for="(item, index) in filters" :key="index" :value="item.term">{{ item.term + " (" +
                        item.count + ")" }}</option>
                </select>
            </div>

            <h2 class="count" aria-live="assertive">{{ texts.search.resultat }} {{ results?.totalMatching || 0 }} {{
                texts.search.traffar }}</h2>
        </div>
        <ul v-if="results?.didYouMean" class="didyoumean">
            <li v-for="(item, index) in results?.didYouMean" :key="index" :class="item.type">
                <a href="#" @click="e => updateQuery(e, item.suggestion)">
                    {{ texts.search.menadeDu }}<span v-html="item.suggestion"></span>
                </a>
            </li>
        </ul>
        <ul class="hitlist">
            <li v-for="(item, index) in results?.hits" :key="index">
                <a :href="getItemUrl(item)" class="hit">
                    <h3 v-html="shortenHtml(item.title)"></h3>
                    <div v-html="shortenHtml(item.excerpt)" class="excerpt"></div>
                </a>
                <a v-for="(cat) in item.categories" :href="getFilterHash(cat.name)" :key="cat.id" class="filter"
                    @click="e => setFilterList(e, cat.name)">#{{ cat.name }} </a>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import api from "../../services/SHServices";
import SearchField from '../SearchField.vue';
import { SearchHit, SearchResult, Term } from '../../types';
import { firstOrDefault, getHashAsDictionary, convertDictionaryToHashString, setHashKey, debounce, shortenHtml } from '../../utils';

const texts = computed(() => uhr.localizedTexts);
const query = ref('');
const selectedFilter = ref('');
const results = ref<SearchResult>();
const filters = ref<Array<Term>>();
const selected = ref('');

const updateQuery = (e: Event, q: string) => {
    e.preventDefault();
    query.value = q;
    setQuery();
}

const setQuery = () => {
    setHashKey('query', query.value);
    debouncedSearch();
}
const setFilterList = (e: Event, filter: string) => {
    e.preventDefault();
    selectedFilter.value = filter;
    setHashKey('filter', filter);
    debouncedSearch();
};

const setFilter = (e: Event) => {
    e.preventDefault();
    selectedFilter.value = selected.value;
    setHashKey('filter', selected.value);
    debouncedSearch();
};

const getFilterHash = (filter: string) => {
    var dict = getHashAsDictionary();
    if (dict.isEmpty) dict = {};
    dict['filter'] = filter;
    return '#' + convertDictionaryToHashString(dict);
};

const getItemUrl = (item: SearchHit) => {
    if (!item)
        return '';
    if (!item.isContact || !uhr.settings?.contactPage?.length)
        return item.url;

    return uhr.settings.contactPage + "#sel=" + item.id;
}

const performSearch = () => {
    api.search(query.value, selectedFilter.value)
        .then(r => {
            results.value = r;
            filters.value = firstOrDefault(r.facets, f => f.name === "SearchCategories")?.terms;
        })
};


const debouncedSearch = debounce(performSearch, 100);

const readParametersFromHash = () => {
    const p = getHashAsDictionary();
    if (p.isEmpty)
        return;

    var isModified = query.value != p.query || selectedFilter.value != p.filter;
    if (isModified) {
        query.value = p.query;
        selectedFilter.value = p.filter;
        debouncedSearch();
    }
};

window.addEventListener('popstate', readParametersFromHash);

onMounted(async () => {
    readParametersFromHash();
});

</script>
